import React from "react";
import {Styles} from "../styles/Styles";

export default class Content extends React.Component<any, any> {

    render() {
        return (
          <div style={Styles.content}>
              {this.props.children}
          </div>
        );
    }
}
