import React from "react";
import Routing, {Route} from "../services/Routing";
import {Styles} from "../styles/Styles";

const home = require("../assets/banner-home.jpg").default;
const blog = require("../assets/banner-blog.jpg").default;

export default class Banner extends React.Component<any, any> {

    render() {
        return <img style={Styles.banner} src={this.image()} alt={"."}/>
    }

    image() {
        return (Routing.instance.current() === Route.Home) ? home : blog;
    }
}
