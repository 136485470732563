import React from "react";
import {Styles} from "../styles/Styles";
import Routing, {Route} from "../services/Routing";
import ViewUtils from "../utils/view-utils";

export class NavigationButton extends React.Component<any, any> {
    render() {
        const {button, routeTo} = this.props;
        return (
            <div onClick={() => routeTo(button.route)} style={Styles.navigation.button}>
                {button.title}
            </div>
        );
    }
}

export class NavigationBar extends React.Component<any, any> {

    TITLE = "aare.dev";
    SEPARATOR = " | ";

    buttons = [
        {title: "Home", route: Route.Home},
        {title: "Blog", route: Route.Blog}
    ];
    render() {
        this.title();
        return (
            <div style={Styles.navigation.bar}>
                <span style={Styles.navigation.text}>{this.title()}</span>
                <div style={Styles.navigation.buttons}>
                    {this.buttons.map((button: any, index: number) => {
                        return <NavigationButton key={index} button={button} routeTo={this.props.routeTo}/>
                    })}
                </div>
            </div>
        );
    }

    title() {
        let result = this.TITLE;

        if (ViewUtils.isMobile()) {
            return result;
        }
        if (Routing.instance.current() === Route.Home) {
            result += this.SEPARATOR + "Welcome";
        } else {
            result += this.SEPARATOR + "Blogging Molly";
        }

        return result;
    }
}
