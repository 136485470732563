import {createBrowserHistory} from "history";

const HISTORY = createBrowserHistory();

export enum Route {
    Home = "#/",
    Blog = "#/blog",
    Post = "#/blog?post="
}

export default class Routing {

    public static instance = new Routing();

    public history() {
        return HISTORY;
    }

    public to(route: string) {
        HISTORY.push(route);
    }

    public current() {
        const hash = HISTORY.location.hash;
        if (hash === "") {
            return Route.Home;
        }
        return hash as Route;
    }

    public getUrlParameter(key: string) {
        const params = window.location.hash;
        if (!params) {
            return;
        }
        const split = params.split("?");
        if (split.length < 2) {
            return;
        }

        let search = split[1];

        let result = null;
        let tmp = [];
        search
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === key) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }

    isAt(route: Route) {
        return this.current().includes(route);
    }
}
