
export class Networking {

    private static BASE_URL = "https://aare.dev/";
    private static BLOG_URL = `${Networking.BASE_URL}posts/`;
    private static RANT_URL = `${Networking.BLOG_URL}initial-commit.md`;

    public static instance = new Networking();

    public async fetchBlogIndex(): Promise<any> {
        const result = await fetch(Networking.BLOG_URL + "index.json");
        return await result.json();
    }

    public async fetchRant(): Promise<string> {
        const result = await fetch(Networking.RANT_URL);
        return await result.text();
    }

    async fetchPost(name: string): Promise<string> {
        const result = await fetch(Networking.BLOG_URL + name + ".md");
        return await result.text();
    }
}
