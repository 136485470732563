import {CSSProperties} from "react";

export class Styles {

    static navigation = {
        bar: {
            width: "100%",
            height: "59px",
            backgroundColor: "rgb(30, 30, 30"
        },
        text: {
            color: "rgb(245, 245, 245)",
            lineHeight: "59px",
            marginLeft: "15px",
            fontFamily: "monospace",
            fontSize: "1.4em"
        },
        buttons: {
            float: "right",
            display: "flex",
            flexDirection: "row"
        } as CSSProperties,
        button: {
            lineHeight: "59px",
            color: "white",
            marginRight: "20px",
            cursor: "pointer",
            fontFamily: "monospace",
            fontSize: "1.3em"
        }
    };

    static banner: CSSProperties = {
        width: "100%",
        maxHeight: "500px",
        objectFit: "cover"
    };

    static content: CSSProperties = {

        width: "100%",
        maxWidth: "768px",
        margin: "auto",
    };

    static header: CSSProperties = {
        width: "100%",
        textAlign: "center",
        fontSize: "1.5em",
        fontFamily: "monospace"
    };

    static background: CSSProperties = {
        height: "100%",
        width: "100%",
        paddingTop: "80px",
        paddingBottom: "100px"
    };

    static blog = {
        post: {
            container: {

                border: "1px solid lightgray",
                borderRadius: "5px",
                padding: "4%",
                cursor: "pointer"
            },
            index: {
                fontWeight: "bold",
                marginRight: "10px"
            } as CSSProperties,
            title: {
                lineHeight: "50px",
                height: "50px",

                width: "100%",
                borderBottom: "1px solid lightgray"
            },
            abstract: {
                marginTop: "20px"
            }
        }
    };
    static post: CSSProperties = {
        border: "1px solid lightgray",
        backgroundColor: "rgb(230, 230, 230)",
        padding: "20px",
    };
}
