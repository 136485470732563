import React from 'react';
import {Styles} from "./styles/Styles";
import {NavigationBar} from "./subviews/navigation-bar";
import {Blog} from "./subviews/blog";
import Routing, {Route} from "./services/Routing";
import Home from "./subviews/home";
import Banner from "./subviews/banner";
import BlogPost from "./subviews/blog-post";

export default class App extends React.Component<any, any> {

    route(route: Route) {
        Routing.instance.to(route);
        this.forceUpdate();
    }

    render() {
        return (
            <div>
                <NavigationBar routeTo={(route: Route) => this.route(route)}/>
                <Banner/>
                <div style={Styles.background}>
                    {this.renderContent()}
                </div>
            </div>
        );
    }

    renderContent() {

        if (Routing.instance.isAt(Route.Post)) {
            return <BlogPost/>
        }
        if (Routing.instance.isAt(Route.Blog)) {
            return <Blog routeTo={(route: Route) => this.route(route)}/>
        }
        return <Home/>
    }
}
