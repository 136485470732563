import React from "react";
import {Styles} from "../styles/Styles";
import Routing, {Route} from "../services/Routing";

export default class BlogPostListItem extends React.Component<any, any> {

    componentDidMount(): void {

    }

    render() {
        return (
          <div style={Styles.blog.post.container} onClick={() => this.route()}>
              <div style={Styles.blog.post.title}>
                  <span style={Styles.blog.post.index}>{"#" + this.props.post.id}</span>
                  <span>{this.props.post.title}</span>
              </div>
              <div style={Styles.blog.post.abstract}>
                  {this.props.post.abstract}
              </div>
          </div>
        );
    }

    route() {
        const name = this.props.post.filename.replace(".md", "");
        this.props.routeTo(Route.Post + name);
    }
}
