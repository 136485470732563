import React from "react";
import Content from "./content";
import {Styles} from "../styles/Styles";

export default class Home extends React.Component<any, any> {
    render() {
        return (
            <Content>
                <div style={Styles.header}>I have no idea what I'm doing 🤔</div>
            </Content>
        );
    }
}
