import React from "react";
import {Styles} from "../styles/Styles";
import Content from "./content";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import 'github-markdown-css'
import Routing from "../services/Routing";
import {Networking} from "../services/Networking";

export default class BlogPost extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            post: ""
        }
    }

    async componentDidMount(): Promise<void> {
        const name = Routing.instance.getUrlParameter("post");
        if (name) {
            const post = await Networking.instance.fetchPost(name);
            this.setState({post: post});
        }

    }

    render() {
        return (
            <Content>
                <div style={Styles.post} className='markdown-body'>
                    <ReactMarkdown plugins={[gfm]} children={this.state.post}/>
                </div>
            </Content>
        );
    }
}
