
import React from "react";
import {Networking} from "../services/Networking";

import Content from "./content";
import BlogPostListItem from "./blog-post-list-item";

export class Blog extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            markdown: "",
            posts: []
        }
    }

    async componentDidMount(): Promise<void> {
        // const text = await Networking.instance.fetchRant();
        // this.setState({markdown: text});
        const response = await Networking.instance.fetchBlogIndex();
        this.setState({posts: response.posts});
    }

    render() {
        return (
            <Content>
                {this.state.posts.map((post: any, index: number) => <BlogPostListItem key={index} post={post} routeTo={this.props.routeTo}/>)}
            </Content>
        );
    }
}
